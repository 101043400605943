@import "~/node_modules/sanitize.css/sanitize.css";
@import "~/node_modules/sanitize.css/forms.css";

@import "~/node_modules/hamburgers/_sass/hamburgers/hamburgers";

@import "swiper/swiper.scss";
@import "swiper/modules/pagination/pagination.scss";
@import "swiper/modules/navigation/navigation.scss";

@import "common/variables";

@import "_vendor/cookie";
@import "_vendor/hamburger";

@import "common/fonts";
@import "common/global";
@import "common/typo";

@import "components/header";
@import "components/footer";
@import "components/content";
@import "components/button";
@import "components/slider";

@import "components/menu";
@import "components/overlay";
